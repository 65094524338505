import React from 'react';

import Layout from 'src/components/layout/layout';
import clientProps from 'src/components/players/client-props';
import Players from 'src/components/players/players';
import SEO from 'src/components/seo';
import { usePrivateRoute } from 'src/utils/auth';
import { getClient } from 'src/utils/helper/clients';

const PlayersPage = () => {
  usePrivateRoute();
  const props = clientProps[getClient()] ?? clientProps.DEFAULT;
  return (
    <Layout>
      <SEO title="Players" />
      {props && <Players {...props} />}
    </Layout>
  );
};

export default PlayersPage;
